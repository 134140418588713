@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f7fafc;
}

svg {
    display: inline-block;
}

.number-input {
    width: 50px;
    min-width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.border-top-blue {
    border-top: 2px solid blue;
}